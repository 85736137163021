import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { Link } from "gatsby";
import AboutData from "../../data/AdminAbout.json";

class Default404 extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`Teşekkürler| ${config.siteTitle}`} />
        </div>
        <div className="page">
          <section className="page-image-section">
            <div className="page-image-wrapper">
              <img className="page-image" alt="404" src={AboutData.image} />
              <h1 className="page-title" style={{ textAlign: "center" }}>
                Teşekkürler
              </h1>
            </div>
          </section>
          <p className="page-default">
            Formunuz tarafımıza ulaşmıştır. En kısa sürede sizinle iletişime
            geçeceğiz.
            <br />
            <a href="mailto:info@buyukyilmaz.av.tr">info@buyukyilmaz.av.tr</a>
            <br />
            <br />
            <Link to="/" title="Ana sayfa">
              {" "}
              &larr; Ana sayfaya dön
            </Link>
          </p>
        </div>
      </Layout>
    );
  }
}

export default Default404;
